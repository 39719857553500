import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
    meta:{
      title: 'Homepage - American Investment Council'
    }
  },
  {
    path: '/about-the-aic',
    name: 'about-the-aic',
    component: () => import(/* webpackChunkName: "about-the-aic" */ '../views/about-the-aic.vue'),
    meta:{
      title: 'About the AIC - American Investment Council'
    }
  },
  {
    path: '/board-of-directors',
    name: 'board-of-directors',
    component: () => import(/* webpackChunkName: "board-of-directors" */ '../views/board-of-directors.vue'),
    meta:{
      title: 'Board of Directors - American Investment Council'
    }
  },
  {
    path: '/aic-staff',
    name: 'aic-staff',
    component: () => import(/* webpackChunkName: "aic-staff" */ '../views/aic-staff.vue'),
    meta:{
      title: 'Board of Directors - American Investment Council'
    }
  },
  {
    path: '/top-priorities',
    name: 'top-priorities',
    component: () => import(/* webpackChunkName: "top-priorities" */ '../views/top-priorities.vue'),
    meta:{
      title: 'Top Priorities - American Investment Council'
    }
  },
  {
    path: '/private-equity-at-work',
    name: 'private-equity-at-work',
    component: () => import(/* webpackChunkName: "private-equity-at-work" */ '../views/private-equity-at-work.vue'),
    meta:{
      title: 'This is Private Equity - American Investment Council'
    }
  },
  {
    path: '/private-equity-in-your-community',
    name: 'private-equity-in-your-community',
    component: () => import(/* webpackChunkName: "private-equity-in-your-community" */ '../views/private-equity-in-your-community.vue'),
    meta:{
      title: 'Private Equity in Your Community - American Investment Council'
    }
  },
  {
    path: '/guidelines-for-responsible-investing',
    name: 'guidelines-for-responsible-investing',
    component: () => import(/* webpackChunkName: "guidelines-for-responsible-investing" */ '../views/guidelines-for-responsible-investing.vue'),
    meta:{
      title: 'Guidelines for Responsible Investing - American Investment Council'
    }
  },
  {
    path: '/private-equity-faqs',
    name: 'private-equity-faqs',
    component: () => import(/* webpackChunkName: "private-equity-faqs" */ '../views/private-equity-faqs.vue'),
    meta:{
      title: 'Private Equity FAQs - American Investment Council'
    }
  },
  {
    path: '/watch-and-learn',
    name: 'watch-and-learn',
    component: () => import(/* webpackChunkName: "watch-and-learn" */ '../views/watch-and-learn.vue'),
    meta:{
      title: 'Watch and Learn - American Investment Council'
    }
  },
  {
    path: '/research',
    name: 'research',
    component: () => import(/* webpackChunkName: "research" */ '../views/research.vue'),
    meta:{
      title: 'Research - American Investment Council'
    }
  },
  {
    path: '/category/comment-letters',
    name: 'comment-letters',
    component: () => import(/* webpackChunkName: "comment-letters" */ '../views/category/comment-letters.vue'),
    meta:{
      title: 'American Investment Council'
    }
  },
  {
    path: '/press-inquiries',
    name: 'press-inquiries',
    component: () => import(/* webpackChunkName: "press-inquiries" */ '../views/press-inquiries.vue'),
    meta:{
      title: 'Press Inquiries - American Investment Council'
    }
  },
  {
    path: '/industry-perspectives',
    name: 'industry-perspectives',
    component: () => import(/* webpackChunkName: "industry-perspectives" */ '../views/industry-perspectives.vue'),
    meta:{
      title: 'Industry Perspectives - American Investment Council'
    }
  },
  {
    path: '/connect',
    name: 'connect',
    component: () => import(/* webpackChunkName: "connect" */ '../views/connect.vue'),
    meta:{
      title: 'Connect - American Investment Council'
    }
  },
  {
    path: '/smallbusiness',
    name: 'smallbusiness',
    component: () => import(/* webpackChunkName: "smallbusiness" */ '../views/smallbusiness.vue'),
    meta:{
      title: 'PRIVATE EQUITY POWERS SMALL BUSINESSES - American Investment Council'
    }
  },
  {
    path: '/healthcare',
    name: 'healthcare',
    component: () => import(/* webpackChunkName: "healthcare" */ '../views/healthcare.vue'),
    meta:{
      title: 'PRIVATE EQUITY IS IMPROVING HEALTH CARE - American Investment Council'
    }
  },
  {
    path: '/privatecredit',
    name: 'privatecredit',
    component: () => import(/* webpackChunkName: "privatecredit" */ '../views/privatecredit.vue'),
    meta:{
      title: 'Private Credit - American Investment Council'
    }
  },
  {
    path: '/join-the-council',
    name: 'join-the-council',
    component: () => import(/* webpackChunkName: "join-the-council" */ '../views/join-the-council.vue'),
    meta:{
      title: 'Join the AIC - American Investment Council'
    }
  },
  {
    path: '/content_type/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/content_type/news.vue'),
    meta:{
      title: 'American Investment Council'
    }
  },
  {
    path: '/builders',
    name: 'builders',
    component: () => import(/* webpackChunkName: "builders" */ '../views/builders.vue'),
    meta:{
      title: 'American Investment Council'
    }
  },
  {
    path: '/maloney-op-ed-in-alphaweek-new-partnerships-between-banks-and-private-credit-lenders-deliver-opportunities-for-businesses-across-the-country',
    name: 'maloney-op-ed-in-alphaweek-new-partnerships-between-banks-and-private-credit-lenders-deliver-opportunities-for-businesses-across-the-country',
    component: () => import(/* webpackChunkName: "maloney-op-ed-in-alphaweek-new-partnerships-between-banks-and-private-credit-lenders-deliver-opportunities-for-businesses-across-the-country" */ '../views/maloney-op-ed-in-alphaweek-new-partnerships-between-banks-and-private-credit-lenders-deliver-opportunities-for-businesses-across-the-country.vue'),
    meta:{
      title: 'Maloney Op-Ed in AlphaWeek: New Partnerships Between Banks and Private Credit Lenders Deliver Opportunities for Businesses Across the Country - American Investment Council'
    }
  },
  {
    path: '/supporting-small-businesses',
    name: 'supporting-small-businesses',
    component: () => import(/* webpackChunkName: "supporting-small-businesses" */ '../views/supporting-small-businesses.vue'),
    meta:{
      title: 'Supporting Small Businesses - American Investment Council'
    }
  },
  {
    path: '/american-investment-council-releases-new-video-highlighting-private-equity-investments-in-small-businesses',
    name: 'american-investment-council-releases-new-video-highlighting-private-equity-investments-in-small-businesses',
    component: () => import(/* webpackChunkName: "american-investment-council-releases-new-video-highlighting-private-equity-investments-in-small-businesses" */ '../views/in-small-businesses.vue'),
    meta:{
      title: 'American Investment Council Releases New Video Highlighting Private Equity Investments in Small Businesses - American Investment Council'
    }
  },
  {
    path: '/icymi-private-equity-is-not-the-boogeyman-in-healthcare',
    name: 'icymi-private-equity-is-not-the-boogeyman-in-healthcare',
    component: () => import(/* webpackChunkName: "icymi-private-equity-is-not-the-boogeyman-in-healthcare" */ '../views/icymi-private-equity-is-not-the-boogeyman-in-healthcare.vue'),
    meta:{
      title: 'ICYMI: DePaul University Professor Outlines How the Private Equity Industry Plays an Essential Role in the U.S. Health Care System - American Investment Council'
    }
  },
  {
    path: '/house-small-business-committee-subcommittee-hearing-highlights-how-private-capital-provides-critical-support-to-small-businesses',
    name: 'house-small-business-committee-subcommittee-hearing-highlights-how-private-capital-provides-critical-support-to-small-businesses',
    component: () => import(/* webpackChunkName: "house-small-business-committee-subcommittee-hearing-highlights-how-private-capital-provides-critical-support-to-small-businesses" */ '../views/house-small-business-committee-subcommittee-hearing-highlights-how-private-capital-provides-critical-support-to-small-businesses.vue'),
    meta:{
      title: 'House Small Business Committee Subcommittee Hearing Highlights How Private Capital Provides Critical Support to Small Businesses - American Investment Council'
    }
  },
  {
    path: '/maloney-op-ed-in-realclearhealth-private-equity-is-the-partner-health-care-needs',
    name: 'maloney-op-ed-in-realclearhealth-private-equity-is-the-partner-health-care-needs',
    component: () => import(/* webpackChunkName: "maloney-op-ed-in-realclearhealth-private-equity-is-the-partner-health-care-needs" */ '../views/maloney-op-ed-in-realclearhealth-private-equity-is-the-partner-health-care-needs.vue'),
    meta:{
      title: 'Maloney Op-Ed in RealClearHealth: Private Equity Is the Partner Health Care Needs - American Investment Council'
    }
  },
  {
    path: '/aic-and-punchbowl-news-host-event-with-gov-brian-kemp-r-ga-and-zaxbys-ceo-examining-how-private-equity-is-strengthening-georgia-small-businesses',
    name: 'aic-and-punchbowl-news-host-event-with-gov-brian-kemp-r-ga-and-zaxbys-ceo-examining-how-private-equity-is-strengthening-georgia-small-businesses',
    component: () => import(/* webpackChunkName: "aic-and-punchbowl-news-host-event-with-gov-brian-kemp-r-ga-and-zaxbys-ceo-examining-how-private-equity-is-strengthening-georgia-small-businesses" */ '../views/aic-and-punchbowl-news-host-event-with-gov-brian-kemp-r-ga-and-zaxbys-ceo-examining-how-private-equity-is-strengthening-georgia-small-businesses.vue'),
    meta:{
      title: 'AIC and Punchbowl News Host Event with Gov. Brian Kemp (R-GA) and Zaxby’s CEO Examining How Private Equity is Strengthening Georgia Small Businesses - American Investment Council'
    }
  },
  {
    path: '/wsj-ed-board-pushes-back-on-extreme-washington-regulations-that-raise-prices-discourage-investment',
    name: 'wsj-ed-board-pushes-back-on-extreme-washington-regulations-that-raise-prices-discourage-investment',
    component: () => import(/* webpackChunkName: "wsj-ed-board-pushes-back-on-extreme-washington-regulations-that-raise-prices-discourage-investment" */ '../views/wsj-ed-board-pushes-back-on-extreme-washington-regulations-that-raise-prices-discourage-investment.vue'),
    meta:{
      title: 'WSJ Ed Board Pushes Back on Extreme Washington Regulations that Raise Prices & Discourage Investment - American Investment Council'
    }
  },
  {
    path: '/aic-speaks-out-against-agenda-driven-ftc-public-workshop-that-ignores-private-equitys-full-record',
    name: 'aic-speaks-out-against-agenda-driven-ftc-public-workshop-that-ignores-private-equitys-full-record',
    component: () => import(/* webpackChunkName: "aic-speaks-out-against-agenda-driven-ftc-public-workshop-that-ignores-private-equitys-full-record" */ '../views/aic-speaks-out-against-agenda-driven-ftc-public-workshop-that-ignores-private-equitys-full-record.vue'),
    meta:{
      title: 'AIC Speaks Out Against Agenda-Driven FTC “Public Workshop” That Ignores Private Equity’s Full Record - American Investment Council'
    }
  },
  {
    path: '/small-business-saturday-private-equity-is-smaller-than-you-think',
    name: 'small-business-saturday-private-equity-is-smaller-than-you-think',
    component: () => import(/* webpackChunkName: "small-business-saturday-private-equity-is-smaller-than-you-think" */ '../views/small-business-saturday-private-equity-is-smaller-than-you-think.vue'),
    meta:{
      title: 'Small Business Saturday: Private Equity is Smaller Than You Think - American Investment Council'
    }
  },
  {
    path: '/state-of-the-union-week-private-equity-is-helping-american-manufacturers-grow-and-expand',
    name: 'state-of-the-union-week-private-equity-is-helping-american-manufacturers-grow-and-expand',
    component: () => import(/* webpackChunkName: "state-of-the-union-week-private-equity-is-helping-american-manufacturers-grow-and-expand" */ '../views/state-of-the-union-week-private-equity-is-helping-american-manufacturers-grow-and-expand.vue'),
    meta:{
      title: 'State of the Union Week: Private Equity is Helping American Manufacturers Grow and Expand - American Investment Council'
    }
  },
  {
    path: '/hospitals-depend-on-private-equity-to-support-better-patient-outcomes-and-improve-efficiencies',
    name: 'hospitals-depend-on-private-equity-to-support-better-patient-outcomes-and-improve-efficiencies',
    component: () => import(/* webpackChunkName: "hospitals-depend-on-private-equity-to-support-better-patient-outcomes-and-improve-efficiencies" */ '../views/improve-efficiencies.vue'),
    meta:{
      title: 'Hospitals Depend on Private Equity to Support Better Patient Outcomes and Improve Efficiencies - American Investment Council'
    }
  },
  {
    path: '/private-equity-investments-support-american-health-care-covid-19-response',
    name: 'private-equity-investments-support-american-health-care-covid-19-response',
    component: () => import(/* webpackChunkName: "private-equity-investments-support-american-health-care-covid-19-response" */ '../views/private-equity-investments-support-american-health-care-covid-19-response.vue'),
    meta:{
      title: 'Private Equity Investments Support American Health Care & COVID-19 Response - American Investment Council'
    }
  },
  {
    path: '/health-care',
    name: 'health-care',
    component: () => import(/* webpackChunkName: "health-care" */ '../views/health-care.vue'),
    meta:{
      title: 'Health Care - American Investment Council'
    }
  },
  {
    path: '/debevoise-plimpton-renewable-energy-investments',
    name: 'debevoise-plimpton-renewable-energy-investments',
    component: () => import(/* webpackChunkName: "debevoise-plimpton-renewable-energy-investments" */ '../views/debevoise-plimpton-renewable-energy-investments.vue'),
    meta:{
      title: 'Debevoise & Plimpton: Renewable Energy Investments – International Protections to Mitigate Risks - American Investment Council'
    }
  },
  {
    path: '/massumi-consoli-llp-looking-back-and-looking-ahead',
    name: 'massumi-consoli-llp-looking-back-and-looking-ahead',
    component: () => import(/* webpackChunkName: "massumi-consoli-llp-looking-back-and-looking-ahead" */ '../views/massumi-consoli-llp-looking-back-and-looking-ahead.vue'),
    meta:{
      title: 'Massumi + Consoli LLP: Looking Back and Looking Ahead - American Investment Council'
    }
  },
  {
    path: '/aic-nationwide-press-tour-private-equity-supports-small-businesses-local-jobs',
    name: 'aic-nationwide-press-tour-private-equity-supports-small-businesses-local-jobs',
    component: () => import(/* webpackChunkName: "aic-nationwide-press-tour-private-equity-supports-small-businesses-local-jobs" */ '../views/aic-nationwide-press-tour-private-equity-supports-small-businesses-local-jobs.vue'),
    meta:{
      title: 'AIC Nationwide Press Tour: Private Equity Supports Small Businesses & Local Jobs - American Investment Council'
    }
  },
  {
    path: '/aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches',
    name: 'aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches.vue'),
    meta:{
      title: 'AIC Comment Letter to the NAIC on RBC Factor for Asset-Backed Security Residual Tranches - American Investment Council'
    }
  },
  {
    path: '/aic-comment-letter-to-the-naic-on-framework-for-regulation-of-insurer-investments',
    name: 'aic-comment-letter-to-the-naic-on-framework-for-regulation-of-insurer-investments',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/aic-comment-letter-to-the-naic-on-framework-for-regulation-of-insurer-investments.vue'),
    meta:{
      title: 'AIC Comment Letter to the NAIC on Framework for Regulation of Insurer Investments - American Investment Council'
    }
  },
  {
    path: '/aic-to-ftc-and-chair-khan-re-health-care-workshop',
    name: 'aic-to-ftc-and-chair-khan-re-health-care-workshop',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/aic-to-ftc-and-chair-khan-re-health-care-workshop.vue'),
    meta:{
      title: 'AIC Calls on FTC to Make Sure its Health Care Workshop is Objective - American Investment Council'
    }
  },
  {
    path: '/aic-comment-letter-to-the-naic-on-amendment-to-the-naic-iao-pp-manual-authorizing-procedures-for-svo-discretion-over-naic-designations-assigned-through-the-filing-exemption-process',
    name: 'aic-comment-letter-to-the-naic-on-amendment-to-the-naic-iao-pp-manual-authorizing-procedures-for-svo-discretion-over-naic-designations-assigned-through-the-filing-exemption-process',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/aic-comment-letter-to-the-naic-on-amendment-to-the-naic-iao-pp-manual-authorizing-procedures-for-svo-discretion-over-naic-designations-assigned-through-the-filing-exemption-process.vue'),
    meta:{
      title: 'AIC Comment Letter to the NAIC on Amendment to the NAIC IAO P&P Manual Authorizing Procedures for SVO Discretion over NAIC Designations Assigned Through the Filing Exemption Process - American Investment Council'
    }
  },
  {
    path: '/aic-comment-letter-on-the-department-of-labors-fiduciary-rule-proposal',
    name: 'aic-comment-letter-on-the-department-of-labors-fiduciary-rule-proposal',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/aic-comment-letter-on-the-department-of-labors-fiduciary-rule-proposal.vue'),
    meta:{
      title: 'AIC Comment Letter on the Department of Labor’s Fiduciary Rule Proposal - American Investment Council'
    }
  },
  {
    path: '/aic-comment-letter-to-e-committee-re-holistic-framework',
    name: 'aic-comment-letter-to-e-committee-re-holistic-framework',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/aic-comment-letter-to-e-committee-re-holistic-framework.vue'),
    meta:{
      title: 'AIC Letter to National Association of Insurance Commissioners (NAIC) Regarding  Framework for Regulation of Insurer Investments - American Investment Council'
    }
  },
  {
    path: '/aic-final-letter-to-the-treasury-on-outbound-investment',
    name: 'aic-final-letter-to-the-treasury-on-outbound-investment',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/aic-final-letter-to-the-treasury-on-outbound-investment.vue'),
    meta:{
      title: 'Debevoise & Plimpton: Renewable Energy Investments – International Protections to Mitigate Risks - American Investment Council'
    }
  },
  {
    path: '/aic-response-to-ftc-doj-proposed-new-merger-guidelines-continued',
    name: 'aic-response-to-ftc-doj-proposed-new-merger-guidelines-continued',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/aic-response-to-ftc-doj-proposed-new-merger-guidelines-continued.vue'),
    meta:{
      title: 'AIC Comment Letter to the FTC’s Proposed New Premerger Notification Requirements - American Investment Council'
    }
  },
  {
    path: '/aic-response-to-ftc-doj-proposed-new-merger-guidelines',
    name: 'aic-response-to-ftc-doj-proposed-new-merger-guidelines',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/aic-response-to-ftc-doj-proposed-new-merger-guidelines.vue'),
    meta:{
      title: 'AIC Response to FTC/DOJ proposed new Merger Guidelines - American Investment Council'
    }
  },
  {
    path: '/aic-comment-letter-to-treasury-hhs-cfpb-regarding-medical-payment-products',
    name: 'aic-comment-letter-to-treasury-hhs-cfpb-regarding-medical-payment-products',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/aic-comment-letter-to-treasury-hhs-cfpb-regarding-medical-payment-products.vue'),
    meta:{
      title: 'AIC Comment Letter to Treasury/HHS/CFPB Regarding Medical Payment Products - American Investment Council'
    }
  },
  {
    path: '/aic-comment-letter-to-the-sec-on-the-interconnectedness-of-proposed-rules-impacting-investment-advisers',
    name: 'aic-comment-letter-to-the-sec-on-the-interconnectedness-of-proposed-rules-impacting-investment-advisers',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/aic-comment-letter-to-the-sec-on-the-interconnectedness-of-proposed-rules-impacting-investment-advisers.vue'),
    meta:{
      title: 'AIC Comment Letter to the SEC on the Interconnectedness of Proposed Rules Impacting Investment Advisers - American Investment Council'
    }
  },
  {
    path: '/aic-response-to-fsoc-proposed-new-nonbank-guidance',
    name: 'aic-response-to-fsoc-proposed-new-nonbank-guidance',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/aic-response-to-fsoc-proposed-new-nonbank-guidance.vue'),
    meta:{
      title: 'AIC Response to FSOC Proposed New Nonbank Guidance - American Investment Council'
    }
  },
  {
    path: '/aic-letter-regarding-clo-model-pre-payment-and-discount-assumptions',
    name: 'aic-letter-regarding-clo-model-pre-payment-and-discount-assumptions',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/aic-letter-regarding-clo-model-pre-payment-and-discount-assumptions.vue'),
    meta:{
      title: 'AIC Letter Regarding CLO Model Pre-Payment and Discount Assumptions - American Investment Council'
    }
  },
  {
    path: '/aic-comment-letter-to-sec-on-proposed-rule-regarding-safeguarding-advisory-client-assets',
    name: 'aic-comment-letter-to-sec-on-proposed-rule-regarding-safeguarding-advisory-client-assets',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/aic-comment-letter-to-sec-on-proposed-rule-regarding-safeguarding-advisory-client-assets.vue'),
    meta:{
      title: 'AIC Comment Letter to SEC On Proposed Rule Regarding Safeguarding Advisory Client Assets - American Investment Council'
    }
  },
  {
    path: '/aic-comment-letter-to-ftc-on-noncompete-agreements',
    name: 'aic-comment-letter-to-ftc-on-noncompete-agreements',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/aic-comment-letter-to-ftc-on-noncompete-agreements.vue'),
    meta:{
      title: 'AIC Comment Letter to FTC On Noncompete Agreements - American Investment Council'
    }
  },
  {
    path: '/aic-comment-letter-to-hhs-on-disclosure-of-pe-backed-skilled-nursing-homes',
    name: 'aic-comment-letter-to-hhs-on-disclosure-of-pe-backed-skilled-nursing-homes',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/aic-comment-letter-to-hhs-on-disclosure-of-pe-backed-skilled-nursing-homes.vue'),
    meta:{
      title: 'AIC Comment Letter to HHS on Disclosure of PE backed Skilled Nursing Homes - American Investment Council'
    }
  },
  {
    path: '/aic-comment-letter-to-secs-proposal-to-prohibit-conflicts-of-interests-for-asset-backed-securities',
    name: 'aic-comment-letter-to-secs-proposal-to-prohibit-conflicts-of-interests-for-asset-backed-securities',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/aic-comment-letter-to-secs-proposal-to-prohibit-conflicts-of-interests-for-asset-backed-securities.vue'),
    meta:{
      title: 'AIC Comment Letter to SEC’s Proposal to Prohibit Conflicts of Interests for Asset-Backed-Securities - American Investment Council'
    }
  },
  {
    path: '/proposed-rule-regarding-outsourcing-by-investment-advisers',
    name: 'proposed-rule-regarding-outsourcing-by-investment-advisers',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/proposed-rule-regarding-outsourcing-by-investment-advisers.vue'),
    meta:{
      title: 'Proposed Rule Regarding Outsourcing by Investment Advisers - American Investment Council'
    }
  },
  {
    path: '/aic-submitted-comment-letter-to-naic-on-their-clo',
    name: 'aic-submitted-comment-letter-to-naic-on-their-clo',
    component: () => import(/* webpackChunkName: "aic-comment-letter-to-the-naic-on-rbc-factor-for-asset-backed-security-residual-tranches" */ '../views/category/aic-submitted-comment-letter-to-naic-on-their-clo.vue'),
    meta:{
      title: 'AIC Submitted Comment Letter to NAIC on their CLO - American Investment Council'
    }
  },

  {
    path: '/builders-videos',
    name: 'builders-videos',
    component: () => import(/* webpackChunkName: "builders-videos" */ '../views/builders-videos.vue'),
    meta:{
      title: 'American Investment Council'
    }
  },
  {
    path: '/hci-equity',
    name: 'hci-equity',
    component: () => import(/* webpackChunkName: "hci-equity" */ '../views/hci-equity.vue'),
    meta:{
      title: 'Member Spotlight: Q&A with HCI Equity Partners - American Investment Council'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/privacy-policy.vue'),
    meta:{
      title: 'Privacy Policy - American Investment Council'
    }
  },
  {
    path: '/private-equity-is-smaller-than-you-think-2',
    name: 'private-equity-is-smaller-than-you-think-2',
    component: () => import(/* webpackChunkName: "private-equity-is-smaller-than-you-think-2" */ '../views/private-equity-is-smaller-than-you-think-2.vue'),
    meta:{
      title: 'Private Equity is Smaller Than You Think - American Investment Council'
    }
  },
  {
    path: '/aic-calls-on-ftc-to-release-materials-from-closed-door-briefing-on-private-equity-in-health-care',
    name: 'aic-calls-on-ftc-to-release-materials-from-closed-door-briefing-on-private-equity-in-health-care',
    component: () => import(/* webpackChunkName: "aic-calls-on-ftc-to-release-materials-from-closed-door-briefing-on-private-equity-in-health-care" */ '../views/aic-calls-on-ftc-to-release-materials-from-closed-door-briefing-on-private-equity-in-health-care.vue'),
    meta:{
      title: 'AIC Calls on FTC to Release Materials from Closed-Door Briefing on Private Equity in Health Care - American Investment Council'
    }
  },
  {
    path: '/urgent-care-centers-rural-health-care-are-stronger-because-of-private-equity-investment',
    name: 'urgent-care-centers-rural-health-care-are-stronger-because-of-private-equity-investment',
    component: () => import(/* webpackChunkName: "urgent-care-centers-rural-health-care-are-stronger-because-of-private-equity-investment" */ '../views/urgent-care-centers-rural-health-care-are-stronger-because-of-private-equity-investment.vue'),
    meta:{
      title: 'Urgent Care Centers & Rural Health Care are Stronger Because of Private Equity Investment - American Investment Council'
    }
  },
  {
    path: '/innovative-medical-devices-that-improve-lives-are-fueled-by-private-equity',
    name: 'innovative-medical-devices-that-improve-lives-are-fueled-by-private-equity',
    component: () => import(/* webpackChunkName: "innovative-medical-devices-that-improve-lives-are-fueled-by-private-equity" */ '../views/innovative-medical-devices-that-improve-lives-are-fueled-by-private-equity.vue'),
    meta:{
      title: 'Innovative Medical Devices that Improve Lives are Fueled by Private Equity - American Investment Council'
    }
  },
  {
    path: '/lifesaving-pharmaceutical-treatments-brought-to-you-by-private-equity',
    name: 'lifesaving-pharmaceutical-treatments-brought-to-you-by-private-equity',
    component: () => import(/* webpackChunkName: "lifesaving-pharmaceutical-treatments-brought-to-you-by-private-equity" */ '../views/lifesaving-pharmaceutical-treatments-brought-to-you-by-private-equity.vue'),
    meta:{
      title: 'Lifesaving Innovation – Brought to You by Private Equity - American Investment Council'
    }
  },
  {
    path: '/new-pitchbook-report-pe-investment-in-urgent-care-centers-has-increased-health-care-access-covid-19-resources-in-rural-communities',
    name: 'new-pitchbook-report-pe-investment-in-urgent-care-centers-has-increased-health-care-access-covid-19-resources-in-rural-communities',
    component: () => import(/* webpackChunkName: "new-pitchbook-report-pe-investment-in-urgent-care-centers-has-increased-health-care-access-covid-19-resources-in-rural-communities" */ '../views/new-pitchbook-report-pe-investment-in-urgent-care-centers-has-increased-health-care-access-covid-19-resources-in-rural-communities.vue'),
    meta:{
      title: 'New PitchBook Report: PE Investment in Urgent Care Centers has Increased Health Care Access, COVID-19 Resources in Rural Communities - American Investment Council'
    }
  },
  {
    path: '/aic-and-naic-form-partnership-to-encourage-more-diversity-in-private-equity-industry',
    name: 'aic-and-naic-form-partnership-to-encourage-more-diversity-in-private-equity-industry',
    component: () => import(/* webpackChunkName: "aic-and-naic-form-partnership-to-encourage-more-diversity-in-private-equity-industry" */ '../views/aic-and-naic-form-partnership-to-encourage-more-diversity-in-private-equity-industry.vue'),
    meta:{
      title: 'AIC and NAIC Form Partnership to Encourage More Diversity in Private Equity Industry - American Investment Council'
    }
  },
  {
    path: '/aic-members-join-diligents-modern-leadership-initiative-to-improve-board-diversity-at-private-equity-backed-businesses',
    name: 'aic-members-join-diligents-modern-leadership-initiative-to-improve-board-diversity-at-private-equity-backed-businesses',
    component: () => import(/* webpackChunkName: "aic-members-join-diligents-modern-leadership-initiative-to-improve-board-diversity-at-private-equity-backed-businesses" */ '../views/aic-members-join-diligents-modern-leadership-initiative-to-improve-board-diversity-at-private-equity-backed-businesses.vue'),
    meta:{
      title: 'AIC Members Join Diligent’s Modern Leadership Initiative To Improve Board Diversity At Private Equity-Backed Businesses - American Investment Council'
    }
  },
  {
    path: '/state',
    name: 'state',
    children: [
      {
        path: 'alabama',
        name: 'alabama',
        component: () => import(/* webpackChunkName: "alabama" */ '../views/state/alabama.vue'),
        meta:{
          title: 'Alabama - American Investment Council'
        }
      },
      {
        path: 'alaska',
        name: 'alaska',
        component: () => import(/* webpackChunkName: "alaska" */ '../views/state/alaska.vue'),
        meta:{
          title: 'Alaska - American Investment Council'
        }
      },
      {
        path: 'california',
        name: 'california',
        component: () => import(/* webpackChunkName: "california" */ '../views/state/california.vue'),
        meta:{
          title: 'California - American Investment Council'
        }
      },
      {
        path: 'arizona',
        name: 'arizona',
        component: () => import(/* webpackChunkName: "arizona" */ '../views/state/arizona.vue'),
        meta:{
          title: 'Arizona - American Investment Council'
        }
      },
      {
        path: 'arkansas',
        name: 'arkansas',
        component: () => import(/* webpackChunkName: "arkansas" */ '../views/state/arkansas.vue'),
        meta:{
          title: 'Arkansas - American Investment Council'
        }
      },
      {
        path: 'colorado',
        name: 'colorado',
        component: () => import(/* webpackChunkName: "colorado" */ '../views/state/colorado.vue'),
        meta:{
          title: 'Colorado - American Investment Council'
        }
      },
      {
        path: 'connecticut',
        name: 'connecticut',
        component: () => import(/* webpackChunkName: "connecticut" */ '../views/state/connecticut.vue'),
        meta:{
          title: 'Connecticut - American Investment Council'
        }
      },
      {
        path: 'delaware',
        name: 'delaware',
        component: () => import(/* webpackChunkName: "delaware" */ '../views/state/delaware.vue'),
        meta:{
          title: 'Delaware - American Investment Council'
        }
      },
      {
        path: 'district-of-columbia',
        name: 'district-of-columbia',
        component: () => import(/* webpackChunkName: "district-of-columbia" */ '../views/state/district-of-columbia.vue'),
        meta:{
          title: 'District of Columbia - American Investment Council'
        }
      },
      {
        path: 'florida',
        name: 'florida',
        component: () => import(/* webpackChunkName: "florida" */ '../views/state/florida.vue'),
        meta:{
          title: 'Kentucky - American Investment Council'
        }
      },
      {
        path: 'georgia',
        name: 'georgia',
        component: () => import(/* webpackChunkName: "georgia" */ '../views/state/georgia.vue'),
        meta:{
          title: 'Kentucky - American Investment Council'
        }
      },
      {
        path: 'hawaii',
        name: 'hawaii',
        component: () => import(/* webpackChunkName: "hawaii" */ '../views/state/hawaii.vue'),
        meta:{
          title: 'Kentucky - American Investment Council'
        }
      },
      {
        path: 'idaho',
        name: 'idaho',
        component: () => import(/* webpackChunkName: "idaho" */ '../views/state/idaho.vue'),
        meta:{
          title: 'Idaho - American Investment Council'
        }
      },
      {
        path: 'illinois',
        name: 'illinois',
        component: () => import(/* webpackChunkName: "illinois" */ '../views/state/illinois.vue'),
        meta:{
          title: 'Illinois - American Investment Council'
        }
      },
      {
        path: 'indiana',
        name: 'indiana',
        component: () => import(/* webpackChunkName: "indiana" */ '../views/state/indiana.vue'),
        meta:{
          title: 'Indiana - American Investment Council'
        }
      },
      {
        path: 'iowa',
        name: 'iowa',
        component: () => import(/* webpackChunkName: "iowa" */ '../views/state/iowa.vue'),
        meta:{
          title: 'Iowa - American Investment Council'
        }
      },
      {
        path: 'kansas',
        name: 'kansas',
        component: () => import(/* webpackChunkName: "kansas" */ '../views/state/kansas.vue'),
        meta:{
          title: 'Kansas - American Investment Council'
        }
      },
      {
        path: 'kentucky',
        name: 'kentucky',
        component: () => import(/* webpackChunkName: "kentucky" */ '../views/state/kentucky.vue'),
        meta:{
          title: 'Kentucky - American Investment Council'
        }
      },
      {
        path: 'louisiana',
        name: 'louisiana',
        component: () => import(/* webpackChunkName: "louisiana" */ '../views/state/louisiana.vue'),
        meta:{
          title: 'Louisiana - American Investment Council'
        }
      },
      {
        path: 'maine',
        name: 'maine',
        component: () => import(/* webpackChunkName: "maine" */ '../views/state/maine.vue'),
        meta:{
          title: 'Maine - American Investment Council'
        }
      },
      {
        path: 'massachusetts',
        name: 'massachusetts',
        component: () => import(/* webpackChunkName: "massachusetts" */ '../views/state/massachusetts.vue'),
        meta:{
          title: 'Massachusetts - American Investment Council'
        }
      },
      {
        path: 'maryland',
        name: 'maryland',
        component: () => import(/* webpackChunkName: "maryland" */ '../views/state/maryland.vue'),
        meta:{
          title: 'Maryland - American Investment Council'
        }
      },
      {
        path: 'michigan',
        name: 'michigan',
        component: () => import(/* webpackChunkName: "michigan" */ '../views/state/michigan.vue'),
        meta:{
          title: 'Michigan - American Investment Council'
        }
      },
      {
        path: 'minnesota',
        name: 'minnesota',
        component: () => import(/* webpackChunkName: "minnesota" */ '../views/state/minnesota.vue'),
        meta:{
          title: 'Minnesota - American Investment Council'
        }
      },
      {
        path: 'mississippi',
        name: 'mississippi',
        component: () => import(/* webpackChunkName: "mississippi" */ '../views/state/mississippi.vue'),
        meta:{
          title: 'Mississippi - American Investment Council'
        }
      },
      {
        path: 'missouri',
        name: 'missouri',
        component: () => import(/* webpackChunkName: "missouri" */ '../views/state/missouri.vue'),
        meta:{
          title: 'Missouri - American Investment Council'
        }
      },
      {
        path: 'montana',
        name: 'montana',
        component: () => import(/* webpackChunkName: "montana" */ '../views/state/montana.vue'),
        meta:{
          title: 'Montana - American Investment Council'
        }
      },
      {
        path: 'nebraska',
        name: 'nebraska',
        component: () => import(/* webpackChunkName: "nebraska" */ '../views/state/nebraska.vue'),
        meta:{
          title: 'Nebraska - American Investment Council'
        }
      },
      {
        path: 'nevada',
        name: 'nevada',
        component: () => import(/* webpackChunkName: "nevada" */ '../views/state/nevada.vue'),
        meta:{
          title: 'Nevada - American Investment Council'
        }
      },
      {
        path: 'new-hampshire',
        name: 'new-hampshire',
        component: () => import(/* webpackChunkName: "new-hampshire" */ '../views/state/new-hampshire.vue'),
        meta:{
          title: 'New Hampshire - American Investment Council'
        }
      },
      {
        path: 'new-jersey',
        name: 'new-jersey',
        component: () => import(/* webpackChunkName: "new-jersey" */ '../views/state/new-jersey.vue'),
        meta:{
          title: 'New Jersey - American Investment Council'
        }
      },
      {
        path: 'new-mexico',
        name: 'new-mexico',
        component: () => import(/* webpackChunkName: "new-mexico" */ '../views/state/new-mexico.vue'),
        meta:{
          title: 'New Mexico - American Investment Council'
        }
      },
      {
        path: 'new-york',
        name: 'new-york',
        component: () => import(/* webpackChunkName: "new-york" */ '../views/state/new-york.vue'),
        meta:{
          title: 'New York - American Investment Council'
        }
      },
      {
        path: 'north-carolina',
        name: 'north-carolina',
        component: () => import(/* webpackChunkName: "north-carolina" */ '../views/state/north-carolina.vue'),
        meta:{
          title: 'North Carolina - American Investment Council'
        }
      },
      {
        path: 'north-dakota',
        name: 'north-dakota',
        component: () => import(/* webpackChunkName: "north-dakota" */ '../views/state/north-dakota.vue'),
        meta:{
          title: 'North Dakota - American Investment Council'
        }
      },
      {
        path: 'ohio',
        name: 'ohio',
        component: () => import(/* webpackChunkName: "ohio" */ '../views/state/ohio.vue'),
        meta:{
          title: 'Ohio - American Investment Council'
        }
      },
      {
        path: 'oklahoma',
        name: 'oklahoma',
        component: () => import(/* webpackChunkName: "oklahoma" */ '../views/state/oklahoma.vue'),
        meta:{
          title: 'Oklahoma - American Investment Council'
        }
      },
      {
        path: 'oregon',
        name: 'oregon',
        component: () => import(/* webpackChunkName: "oregon" */ '../views/state/oregon.vue'),
        meta:{
          title: 'Oregon - American Investment Council'
        }
      },
      {
        path: 'pennsylvania',
        name: 'pennsylvania',
        component: () => import(/* webpackChunkName: "pennsylvania" */ '../views/state/pennsylvania.vue'),
        meta:{
          title: 'Pennsylvania - American Investment Council'
        }
      },
      {
        path: 'rhode-island',
        name: 'rhode-island',
        component: () => import(/* webpackChunkName: "rhode-island" */ '../views/state/rhode-island.vue'),
        meta:{
          title: 'Rhode Island - American Investment Council'
        }
      },
      {
        path: 'south-carolina',
        name: 'south-carolina',
        component: () => import(/* webpackChunkName: "south-carolina" */ '../views/state/south-carolina.vue'),
        meta:{
          title: 'South Carolina - American Investment Council'
        }
      },
      {
        path: 'south-dakota',
        name: 'south-dakota',
        component: () => import(/* webpackChunkName: "south-dakota" */ '../views/state/south-dakota.vue'),
        meta:{
          title: 'South Dakota - American Investment Council'
        }
      },
      {
        path: 'tennessee',
        name: 'tennessee',
        component: () => import(/* webpackChunkName: "tennessee" */ '../views/state/tennessee.vue'),
        meta:{
          title: 'Tennessee - American Investment Council'
        }
      },
      {
        path: 'texas',
        name: 'texas',
        component: () => import(/* webpackChunkName: "texas" */ '../views/state/texas.vue'),
        meta:{
          title: 'Texas - American Investment Council'
        }
      },
      {
        path: 'utah',
        name: 'utah',
        component: () => import(/* webpackChunkName: "utah" */ '../views/state/utah.vue'),
        meta:{
          title: 'Utah - American Investment Council'
        }
      },
      {
        path: 'vermont',
        name: 'vermont',
        component: () => import(/* webpackChunkName: "vermont" */ '../views/state/vermont.vue'),
        meta:{
          title: 'Vermont - American Investment Council'
        }
      },
      {
        path: 'washington',
        name: 'washington',
        component: () => import(/* webpackChunkName: "washington" */ '../views/state/washington.vue'),
        meta:{
          title: 'Washington - American Investment Council'
        }
      },
      {
        path: 'west-virginia',
        name: 'west-virginia',
        component: () => import(/* webpackChunkName: "west-virginia" */ '../views/state/west-virginia.vue'),
        meta:{
          title: 'West Virginia - American Investment Council'
        }
      },
      {
        path: 'wisconsin',
        name: 'wisconsin',
        component: () => import(/* webpackChunkName: "wisconsin" */ '../views/state/wisconsin.vue'),
        meta:{
          title: 'Wisconsin - American Investment Council'
        }
      },
      {
        path: 'wyoming',
        name: 'wyoming',
        component: () => import(/* webpackChunkName: "wyoming" */ '../views/state/wyoming.vue'),
        meta:{
          title: 'Wyoming - American Investment Council'
        }
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from, next)=>{
  window.document.title = to.meta.title
  next()
})

export default router
