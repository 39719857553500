import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/styles.css'
import '@/assets/css/loadsys.css'
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/custom-style.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { GlobalVariable } from './global';

const app = createApp(App)
app.config.globalProperties.$GlobalVariable = GlobalVariable
app.use(router).mount('#app')
