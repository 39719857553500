<template>
  <footer id="footer">
    <div class="bg-wrap">
      <img
        class="desktop-content"
        src="@/assets/images/bg-footer.png"
        alt="image description"
      /><img
        class="desktop-mobile"
        src="@/assets/images/bg-footer-mobile.png"
        alt="image description"
      />
    </div>
    <div class="container">
      <div class="footer-columns">
        <div class="col">
          <div class="logo-footer">
            <a href="#"
              ><img
                src="@/assets/images/logo-secondary.png"
                alt="American Investmen Council"
                width="164"
                height="60"
            /></a>
          </div>
          <ul class="footer-navigation">
            <li>
              <a
                class="nav-main-link"
                href="/about-the-aic"
                >About the AIC</a
              >
            </li>
            <li>
              <a
                class="nav-main-link"
                href="/private-equity-at-work"
                >What is Private Equity?</a
              >
            </li>
            <li>
              <a
                class="nav-main-link"
                href="/research"
                >Research</a
              >
            </li>
            <li>
              <a class="nav-main-link" href="/category/comment-letters/"
                >Policy</a
              >
            </li>
            <li>
              <a
                class="nav-main-link"
                href="/content_type/news"
                >News</a
              >
            </li>
            <li>
              <a
                class="nav-main-link"
                href="/connect"
                >Connect</a
              >
            </li>
          </ul>
          <div class="footer-holder desktop-content">
            <span class="copy">2024 Copyright, All Rights Reserved.</span>
            <a href="/privacy-policy"
              >Privacy Policy</a
            >
          </div>
        </div>
        <div class="col">
          <div class="footer-columns">
            <div class="col">
              <a
                class="btn btn-info"
                href="/join-the-council"
                >Join the AIC</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="footer-holder mobile-content">
        <span class="copy">2024 Copyright, All Rights Reserved.</span>
        <a href="/privacy-policy"
          >Privacy Policy</a
        >
      </div>
    </div>
  </footer>
</template>

<script setup>
</script>

<style lang="scss" scoped>
</style>