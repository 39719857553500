<template>
  <div class="sticky-wrap">
    <header id="header" style="">
      <div class="progress scroll-progress" style="display: none;">
        <div
          class="progress-bar"
          role="progressbar"
          style="width: 0%"
        ></div>
      </div>
      <div class="container">
        <div class="logo">
          <a href="/"
            ><img
              src="@/assets/images/logo.png"
              alt="American Investment Council"
              width="164"
              height="60"
          /></a>
        </div>
        <a class="nav-opener" href="#">
          <span></span>
        </a>
        <div class="nav-drop">
          <div class="header-holder">
            <form
              class="header-search-form"
              method="get"
              action="/"
            >
              <div class="field-wrap">
                <input
                  type="text"
                  name="s"
                  placeholder="Enter search term"
                  value=""
                />
                <button type="submit">
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.33333 13.1667C10.2789 13.1667 12.6667 10.7789 12.6667 7.83333C12.6667 4.88781 10.2789 2.5 7.33333 2.5C4.38781 2.5 2 4.88781 2 7.83333C2 10.7789 4.38781 13.1667 7.33333 13.1667Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M13.9996 14.5L11.0996 11.6"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </button>
              </div>
            </form>
            <nav class="nav-menu" id="nav">
              <ul>
                <li
                  class="menu-item-has-children menu-item menu-item-type-post_type menu-item-object-page menu-item-589 menu-item-has-children"
                >
                  <a class="nav-main-link" href="/about-the-aic">
                    About the AIC
                  </a>
                  <div class="drop">
                    <ul>
                      <li class=" ">
                        <a href="/about-the-aic#our-members">Our Members</a>
                      </li>
                      <li class=" ">
                        <a href="/board-of-directors">Board of Directors</a>
                      </li>
                      <li class=" ">
                        <a href="/aic-staff">AIC Staff</a>
                      </li>
                      <li class=" ">
                        <a href="/top-priorities">Top Priorities</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  class="menu-item-has-children menu-item menu-item-type-post_type menu-item-object-page menu-item-18265 menu-item-has-children"
                >
                  <a class="nav-main-link" href="/private-equity-at-work">
                    What is Private Equity?
                  </a>
                  <div class="drop">
                    <ul>
                      <li>
                        <a href="/private-equity-in-your-community"
                          >Private Equity in Your Community</a
                        >
                      </li>
                      <li>
                        <a href="/guidelines-for-responsible-investing"
                          >Responsible Investing</a
                        >
                      </li>
                      <li>
                        <a href="/private-equity-faqs">Private Equity FAQs</a>
                      </li>
                      <li>
                        <a href="/watch-and-learn">Watch and Learn</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  class="menu-item-has-children menu-item menu-item-type-post_type menu-item-object-page menu-item-18623 menu-item-has-children"
                >
                  <a class="nav-main-link" href="/research"> Research </a>
                  <div class="drop">
                    <ul>
                      <li>
                        <a href="/research">Reports</a>
                      </li>
                      <!-- <li>
                        <a href="https://strongerpensions.com/"
                          >Stronger Pensions</a
                        >
                      </li> -->
                    </ul>
                  </div>
                </li>
                <li
                  class="menu-item-has-children menu-item menu-item-type-custom menu-item-object-custom menu-item-19587 menu-item-has-children"
                >
                  <a class="nav-main-link" href="/category/comment-letters/">
                    Policy
                  </a>
                  <div class="drop">
                    <ul>
                      <li>
                        <a href="/category/comment-letters">Comment Letters</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  class="menu-item-has-children menu-item menu-item-type-taxonomy menu-item-object-content_type menu-item-18207 menu-item-has-children"
                >
                  <a class="nav-main-link" href="/content_type/news"> News </a>
                  <div class="drop">
                    <ul>
                      <li class=" ">
                        <a href="/press-inquiries">Press Inquiries</a>
                      </li>
                      <li>
                        <a href="/industry-perspectives"
                          >Industry Perspectives</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  class="menu-item-has-children menu-item menu-item-type-post_type menu-item-object-page menu-item-18774 menu-item-has-children"
                >
                  <a class="nav-main-link" href="/connect"> Connect </a>
                  <div class="drop">
                    <ul>
                      <li class=" ">
                        <a href="/join-the-council">Join the AIC</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <div class="logged-user">
            <span class="user-text">Andrew S.</span>
            <span class="user-image"
              ><img src="@/assets/images/user.svg" alt="image description"
            /></span>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
onMounted(() => {
  $("#nav li.menu-item").hover(
    function () {
      $(this).addClass("hover");
    },
    function () {
      $(this).removeClass("hover");
    }
  );
  $(window).on('scroll',function(){
    const scrollTop = $(this).scrollTop();
    if(scrollTop == 0){
      $('.sticky-wrap').removeClass('fixed-position')
    } else {
      const h = $('#header').outerHeight()
      $('.sticky-wrap').addClass('fixed-position')
      $('.sticky-wrap').height(h+'px')
      $('#header').css({width: document.documentElement.clientWidth+'px',position: 'fixed', top: '0px',left: '0px'})
    }
    // 滚动条高度
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    $('.progress-bar').css({width:  `${(scrollTop / height) * 100}%`})
    
  })
  $('.nav-opener').on('click',function(e){
    e.preventDefault();
    $('body').toggleClass('nav-active')
  })
});
</script>

<style lang="scss" scoped>
</style>